import { HomePageConfig } from 'store/remoteConfig/types';
import { logger } from '@nykaa/logger';
import { pageTypes, pageNamePrefix } from 'shared/constants/dataLayer';
import { getErrorString } from 'store/dataLayer/helpers';
import { ApiHelper } from '@nykaa/utils/network';
import { AdditionalRequestOptions } from '@nykaa/utils/network/types';
import { Inventory, FetchHomePageParams } from './types';
import { SEO_METADATA, SEO_DATA, DWEB } from './constants';
import { PagePrefixes } from 'constants/pagePrefixes';

export const getImpressionLoggingEndpoint = () => {
  const { IMPRESSION_LOG_DOMAIN } = process.env;
  return `${IMPRESSION_LOG_DOMAIN}/log_data/log`;
};

export const getHomePageParams = () => ({
  page_type: 'desktop-homepage',
  page_section: '*',
  device: 'desktop',
  dn_platform: DWEB,
  //catalog_tag_filter: 'men',
});

export const getDataLayer = (
  errorCode: number | null,
  params: FetchHomePageParams
) => {
  const isLandingPage = params.isLandingPage;
  if (errorCode && errorCode > 200) {
    return {
      pageName: getErrorString(errorCode),
      pageType: isLandingPage ? params.page_type : pageTypes.HOME,
    };
  }

  if (isLandingPage) {
    return {
      pageName: `desktop:nlp:${params.page_type || ''}:${
        params.page_data || 'default'
      }`,
      pageType: params.page_type || '',
    };
  }

  return {
    pageName: pageNamePrefix.HOME,
    pageType: pageTypes.HOME,
  };
};

const getCommonFieldsForImpressions = () => {
  try {
    return {
      platform: 'desktop',
      vertical: location.host,
      app_version: '',
      device_model: navigator.userAgent || '',
      session_id: '',
      mc_id: (window.s && window.s['marketingCloudVisitorID']) || '',
      timestamp: new Date().getTime() / 1000,
      source: 'web-sdk',
    };
  } catch (err) {
    logger.error(err, 'Error getting common Fields');
    return {};
  }
};

export const handleImpressions = async (
  impressions: any[],
  customerId: string = '',
  isIndianRegion: boolean = true
) => {
  // Skip events incase region is not india
  if (!isIndianRegion) return;

  const updatedImpressions = impressions.map((impression) => {
    const newImpression = impression;
    if (!newImpression.timestamp) {
      newImpression.timestamp = +new Date() / 1000;
    }

    return newImpression;
  });

  const data = {
    events: updatedImpressions,
    common_fields: {
      ...getCommonFieldsForImpressions(),
      customer_id: customerId,
    },
  };

  const url = getImpressionLoggingEndpoint();
  const securityOptions: AdditionalRequestOptions = { CSRF: false };

  try {
    const response = await ApiHelper(
      url,
      'post',
      data,
      undefined,
      securityOptions
    );

    const status = response?.data?.status;
    if (status !== 1) {
      throw new Error(`Impression log returned a status !== 1: ${status}`);
    }
  } catch (err) {
    logger.error(err, 'Error Posting data for impressions');
    throw err;
  }
};

export const getMetaData = (
  inventories: Inventory[],
  pagePrefix: string = ''
) => {
  const seoData = inventories.find(
    (x: Inventory) => x.widgetData && x.widgetData.widgetType === SEO_DATA
  );

  if (seoData) {
    let {
      widgetData: { params },
    } = seoData;
    params = {
      ...params,
      canonical:
        pagePrefix === PagePrefixes.SPECIAL_PAGE ? params.canonical : '',
    };
    return params;
  } else {
    return SEO_METADATA[__PLATFORM__];
  }
};

export const getCustomPageType = (
  homePageConfig: HomePageConfig,
  pageTypeInQuery: string | null
): string | null => {
  const { status, suffix } = homePageConfig.pageTypeSuffix || {};
  const { page_type } = getHomePageParams();

  if (status && suffix && suffix === pageTypeInQuery) {
    return `${page_type}-${suffix}`;
  }
  return null;
};
