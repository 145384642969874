import AnimatedLoader from 'shared/components/BelowTheFoldControl/AnimatedLoader';
import styled from '@emotion/styled';


const Loading = styled(AnimatedLoader)`
  height: 360px;
`;


export default Loading;

