import React from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { MetaTags, AppLinks } from '@nykaa/seo';
import { MetaInfo } from '@nykaa/seo/types';
import { twitterCards } from '@nykaa/seo/constants';
import InterWoff from 'styles/assets/fonts/Inter.woff2';
// import InterRegularWoff from 'styles/assets/fonts/Inter-Regular.woff2';
// import InterMediumWoff from 'styles/assets/fonts/Inter-Medium.woff2';
// import InterSemiBoldWoff from 'styles/assets/fonts/Inter-SemiBold.woff2';

// ! FIXME: Add Default Values
function View({ metaTitle, metaDescription, metaKeywords, metaImage, canonical, page, id = '', product, ogType, metaRobots }: MetaInfo) {
  const { pathname } = useLocation();
  return (
    <>
      <MetaTags
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        metaKeywords={metaKeywords}
        metaImage={metaImage}
        canonical={canonical}
        path={pathname}
        product={product}
        ogType={ogType}
        cardType={twitterCards.summaryLargeImage}
        metaRobots={metaRobots}
      />
      <AppLinks page={page} id={id} />
      <Helmet>
        <link rel="preload" as="font" href={InterWoff} crossOrigin="" />
      </Helmet>
      {/* <link rel="preload" as="font" href={InterRegularWoff} crossOrigin="" />
      <link rel="preload" as="font" href={InterMediumWoff} crossOrigin="" />
      <link rel="preload" as="font" href={InterSemiBoldWoff} crossOrigin="" /> */}
    </>
  );
}

export default View;
