export const BANNER_CLICK_EVENT = 'banner_click';
export const BANNER_IMPRESSION_EVENT = 'banner_impression';
export const IMPRESSION = 'impression';
export const PRODUCT_IMPRESSION_EVENT = 'product_impression';
export const IMPRESSION_TYPE = {
  PRODUCT: 'PRODUCT',
  BANNER: 'BANNER',
};

export const BUTTON_IMPRESSION = 'widget_button_impression';
export const BUTTON_CLICK = 'widget_button_clicked';
export const CLICK = 'click';
