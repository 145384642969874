import widgetTransformer from '@nykaa/deals-transformer';
import { ApiHelper } from '@nykaa/utils/network';
import { logger } from '@nykaa/logger';

import { queryStringFromParams } from 'shared/helpers/utils/url';
import { getDealsUrl } from 'shared/api/urls';
import { FetchHomePageParams } from './types';
import { getHomePageParams } from './helper';

// interface ProductParams {
//   id: string,
//   skuId?: string,
// }

export const getHomePageWidgets = async (params: FetchHomePageParams, pagePrefix?: string, isPaginationEnabled?: boolean) => {
  const url = getDealsUrl();
  const queryParams = {
    ...getHomePageParams(),
    ...params,
  };

  const queryString = queryStringFromParams(queryParams);

  try {
    const response = await ApiHelper(`${url}${queryString}`, 'get');
    const {
      data: { result },
    } = response;

    const inventoryResult = isPaginationEnabled ? result.inventory_data : result;

    if (!inventoryResult) {
      throw new Error('"result" not found in home page widgets api -> data');
    }

    const transformedData = widgetTransformer(inventoryResult, true, __PLATFORM__, pagePrefix);

    return {
      widgets: transformedData.widgets,
      remaining_inventories_ids: result.remaining_inventories_ids || [], // For pagination API V2 call
      queryParams,
    };
  } catch (e) {
    logger.error(e, 'Error fetching Home page widgets ');
    throw e;
  }
};
