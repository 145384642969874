/* eslint-disable react/no-unused-prop-types */
import React, { useRef, useEffect } from 'react';
import PersonalizedWidgetRenderer from '@nykaa/deals-personalization';
import { connect, useSelector } from 'react-redux';

import { ApiHelper } from '@nykaa/utils/network';
import { getDealsEndpoint } from '../../api/urls';
import { State } from 'shared/store/types';
import { useUserData } from 'shared/hooks/auth';
import { getApiParams } from 'shared/helpers';
import { asyncImpressionsHandler } from 'shared/helpers/impressions/asyncImpressionsHandler';
import { syncImpressionsHandler } from 'shared/helpers/impressions/syncImpressionsHandler';
import { checkIsIndianUser } from 'helpers/utils/user';
import { IMPRESSION_TYPE } from 'helpers/impressions/constants';

interface PaginationParams {
  isPaginationEnabled: boolean;
  bufferSize: number;
  pageLimit: number;
  remainingInventoryIds: string[];
  initialInventoryCount: number;
  noImpressionImmediatePaginationCall?: boolean;
}
interface ApiParams {
  params: object;
  paginationParams: PaginationParams;
}
export interface CustomWidgetRendererProps {
  widgetList: any[];
  isServer: boolean;
  impressionEvent?: string;
  eagerLoadImages?: number;
  eagerLoadInventories?: number;
  theme?: any;
  personalizationEnabled?: boolean;
  regionCountry?: string;
  isPaginationEnabled?: any;
  apiParams?: ApiParams;
  remainingInventoryIds?: string[];
  noImpressionImmediatePaginationCall?: boolean;
  allWidgetsLength?: number;
  queryParamsObject?: object;
  currentBatchLoadingCb?: Function;
  pageData?: string;
}

const mapStateToProps = (
  state: State,
  ownProps: CustomWidgetRendererProps
) => ({
  personalizationEnabled: !!state.remoteConfig.switches.personalizationEnabled,
  regionCountry: state.appReducer?.region?.regionHeaderCountry,
  isPaginationEnabled: !!state?.remoteConfig?.switches?.isPaginationEnabled,
  apiParams: getApiParams(state, ownProps) || {},
});

const CustomWidgetRenderer = ({
  widgetList,
  isServer,
  impressionEvent,
  pageData = 'default',
  theme,
  personalizationEnabled = false,
  isPaginationEnabled = false,
  apiParams,
  currentBatchLoadingCb = () => {},
  regionCountry = '',
  eagerLoadImages,
  eagerLoadInventories,
}: CustomWidgetRendererProps) => {
  const dealsEndpoint = getDealsEndpoint() || '';
  const { id } = useUserData();
  const userIdRef = useRef('');
  const { rootMargin, dnConfigs } = useSelector(
    (state: State) => state.remoteConfig?.configs
  );
  const { dnSwitches } = useSelector(
    (state: State) => state.remoteConfig?.switches
  );
  const dnRemoteConfigs = {
    configs: dnConfigs,
    switches: dnSwitches,
  };
  const setUserId = (newUserId: string = '') => {
    userIdRef.current = newUserId;
  };
  useEffect(() => {
    setUserId(id);
  }, [id]);

  const asyncImpressionCallback = async (impressions: any = []) =>
    asyncImpressionsHandler(
      impressions,
      userIdRef?.current,
      checkIsIndianUser(regionCountry)
    );

  const syncImpressionCallback = (
    impressions: any = [],
    impressionType: string = IMPRESSION_TYPE.BANNER
  ) =>
    syncImpressionsHandler(
      impressions,
      impressionType,
      checkIsIndianUser(regionCountry)
    );

  if (personalizationEnabled) {
    return (
      <PersonalizedWidgetRenderer
        widgetList={widgetList}
        isServer={isServer}
        pageData={pageData}
        isPersonalizationSupported={true}
        impressionEvent={impressionEvent}
        asyncImpressionCallback={asyncImpressionCallback}
        syncImpressionCallback={syncImpressionCallback}
        ApiHelper={ApiHelper}
        dealsEndpoint={dealsEndpoint}
        store={__PLATFORM__ || 'nykaa'}
        theme={theme}
        isDesktop={true}
        isPaginationEnabled={isPaginationEnabled}
        apiParams={apiParams as ApiParams}
        currentBatchLoadingCb={currentBatchLoadingCb}
        rootMargin={rootMargin}
        eagerLoadInventories={eagerLoadInventories}
        eagerLoadImages={eagerLoadImages}
        remoteConfigs={dnRemoteConfigs}
      />
    );
  }

  return (
    <PersonalizedWidgetRenderer
      widgetList={widgetList}
      isServer={isServer}
      isPersonalizationSupported={false}
      impressionEvent={impressionEvent}
      asyncImpressionCallback={asyncImpressionCallback}
      syncImpressionCallback={syncImpressionCallback}
      ApiHelper={ApiHelper}
      dealsEndpoint={dealsEndpoint}
      store={__PLATFORM__ || 'nykaa'}
      theme={theme}
      isDesktop={true}
      isPaginationEnabled={isPaginationEnabled}
      apiParams={apiParams as ApiParams}
      currentBatchLoadingCb={currentBatchLoadingCb}
      rootMargin={rootMargin}
      eagerLoadInventories={eagerLoadInventories}
      eagerLoadImages={eagerLoadImages}
      pageData={pageData}
      remoteConfigs={dnRemoteConfigs}
    />
  );
};

export default connect(mapStateToProps, null)(CustomWidgetRenderer);
