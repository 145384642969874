import { Impression, Event, ButtonImpression, ButtonEvent } from './types';
import { ProductImpressionsEvent } from '@nykaa/utils/retina/types/Retina';
import { PRODUCT_CARD_CLICKED } from '@nykaa/utils/retina/constants';
import {
  BANNER_CLICK_EVENT,
  BANNER_IMPRESSION_EVENT,
  BUTTON_CLICK,
  BUTTON_IMPRESSION,
  CLICK,
  IMPRESSION,
  IMPRESSION_TYPE,
  PRODUCT_IMPRESSION_EVENT,
} from './constants';

export const buttonFilterData = (impression: ButtonImpression) => ({
  button_text: impression?.button_text,
  button_position:
    (impression?.button_position && impression?.button_position - 1) || 0,
  button_status: impression?.button_status || false,
  button_type: impression?.button_type || '',
  widget_filters_all: impression?.widget_filters_all || {},
  widget_filters_selected: impression?.widget_filters_selected || {},
});

export const getCommonEventData = (impression: Impression) => {
  const event: Event = {
    page_name: impression.pageName || '',
    dn_page_type: impression.namespace0,
    dn_page_section: impression.namespace1,
    dn_tile_id: impression.namespace2,
    dn_inventory_position: impression.position1 - 1,
    dn_tile_position: impression.position2 - 1,
    dn_wtype: impression.wtype,
    dn_wtype_version: impression.wtype_version,
    dn_transaction_id: impression.transaction_id,
    dn_tracking_metadata: impression.dn_tracking_metadata,
    dn_request_page_data: impression.pageData1 || '',
    dn_inventory_page_data: impression.pageData2 || '',
    hit_recorded_at: impression.timestamp || new Date().getTime() / 1000,
    dn_brand_ids: impression.brand_ids as any[],
    dn_tile_lang: impression.lang || 'en',
    event_type: '',
    event_name: '',
    dn_ad_type: impression.ad_type,
    dn_video_ad_type: impression.video_ad_type,
    dn_video_total_duration: impression.video_total_duration,
    dn_video_view_duration: impression.video_view_duration,
  };

  return event;
};

// impressions: any[] :- any because it can consume different impressions like ( PRODUCT | BANNER )
export const getImpressionEventData = (
  impression: any,
  impressionType: string
) => {
  // Button Event
  const isWidgetButton = !!impression?.button_type;
  if (isWidgetButton) {
    const buttonImpressionEvent: ButtonEvent = {
      ...getCommonEventData(impression),
      ...buttonFilterData(impression),
      event_type: IMPRESSION,
      event_name: BUTTON_IMPRESSION,
    };
    return buttonImpressionEvent;
  }
  // Impression type is product
  if (impressionType === IMPRESSION_TYPE.PRODUCT) {
    const productImpressionEvent: ProductImpressionsEvent = {
      ...impression,
      event_type: IMPRESSION,
      event_name: PRODUCT_IMPRESSION_EVENT,
    };
    return productImpressionEvent;
  }

  // Default case, when no type is passed
  const event: Event = {
    ...getCommonEventData(impression),
    event_type: IMPRESSION,
    event_name: BANNER_IMPRESSION_EVENT,
    dn_impression_version: impression.event.indexOf('v2') === -1 ? 'v1' : 'v2',
  };

  return event;
};

/**
 * Reason for putting any type impression have changing nature of values based on the
 * different type of the events
 */
export const getClickEventData = (impression: any) => {
  // Button Event
  const isWidgetButton = !!impression?.button_type;
  if (isWidgetButton) {
    const buttonImpressionEvent: ButtonEvent = {
      ...getCommonEventData(impression),
      ...buttonFilterData(impression),
      event_type: CLICK,
      event_name: BUTTON_CLICK,
    };
    return buttonImpressionEvent;
  }
  // event_name is product_card_clicked
  if (impression.event_name === PRODUCT_CARD_CLICKED) {
    const event: Impression | ProductImpressionsEvent = {
      ...impression,
      hit_recorded_at: impression.timestamp || new Date().getTime() / 1000,
    };
    return event;
  }

  // Default case
  const event: Event = {
    ...getCommonEventData(impression),
    event_type: 'click',
    event_name: impression.event_name || BANNER_CLICK_EVENT,
  };

  return event;
};
