import React, { useEffect } from 'react';
import styled from 'App/styled';
import { pushEvent } from '@nykaa/data-layer';
import statusCodes from 'constants/statusCodes';

const NOT_FOUND_IMAGE =
  'https://www.nykaaman.com/skin/frontend/nykaa/men/desktop/images/Page-Not-Found.svg';

const Wrapper = styled.div`
  background: ${(props) => props.theme.brandPrimary};
  width: 100%;
  height: inherit;
  text-align: center;
  position: fixed;
  top: 0;
  z-index: 10;
  overflow: hidden;
  height: 100vh;
`;
const Container = styled.div`
  padding: 30px 20px 20px 20px;
  // height: 80vh;
`;

const BackGround = styled.a`
  background: url(${NOT_FOUND_IMAGE}) no-repeat center;
  height: 100vh;
  display: block;
  width: 100%;
`;
interface ErrorProps {
  statusCode: number;
}

const ERROR_PAGE_LOAD_EVENT = 'errorPageLoad';

function Error({ statusCode }: ErrorProps) {
  useEffect(() => {
    if (statusCode === statusCodes.NOT_FOUND) pushEvent(ERROR_PAGE_LOAD_EVENT);
  }, [statusCode]);

  return (
    <Wrapper>
      <Container>
        <BackGround href="/" />
      </Container>
    </Wrapper>
  );
}

export default Error;
