import { Dispatch } from 'redux';

import statusCodes from 'shared/constants/statusCodes';
import { actionForSSRDataLayer } from 'shared/store/dataLayer/action';
import { pushData } from 'shared/helpers/utils/dataLayer';
import appEventTypes from 'shared/store/app/actionTypes';

import { FetchHomePageParams } from './types';
import actionTypes from './actionTypes';
import { getHomePageWidgets } from './service';
import { getDataLayer, getMetaData, getHomePageParams } from './helper';

export const fetchHomePageWidgets = (params: FetchHomePageParams, pagePrefix?: string, isPaginationEnabled?: boolean) => (dispatch: Dispatch) => {
  const newParams = { ...getHomePageParams(), ...params };
  dispatch({
    type: actionTypes.FETCH_HOME_PAGE_DATA_PROGRESS,
  });
  return getHomePageWidgets(params, pagePrefix, isPaginationEnabled)
    .then(({ widgets, remaining_inventories_ids, queryParams }) => {
      if (widgets && widgets.length > 0) {
        dispatch({
          type: actionTypes.FETCH_HOME_PAGE_DATA_SUCCESS,
          payload: { widgets, styles: {}, remaining_inventories_ids, queryParams },
          metaData: getMetaData(widgets, pagePrefix),
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_HOME_PAGE_DATA_FAILED,
        });
        dispatch({
          type: appEventTypes.APP_REDUCER_STATUS_CODE,
          payload: statusCodes.NOT_FOUND,
        });
      }

      return {
        errorCode: null,
      };
    })
    .catch(err => {
      let status = statusCodes.ERROR;
      if (err.isAxiosError && err.response?.status) {
        status = err.response.status;
      }

      dispatch({
        type: actionTypes.FETCH_HOME_PAGE_DATA_FAILED,
      });

      dispatch({
        type: appEventTypes.APP_REDUCER_STATUS_CODE,
        payload: status,
      });

      return {
        errorCode: status,
      };
    })
    .then(({ errorCode }) => {
      const dataLayer = getDataLayer(errorCode, params);
      if (__SERVER__) {
        dispatch(actionForSSRDataLayer({ ...dataLayer, widgetsPageType: newParams.page_type }));
      } else {
        pushData({ ...dataLayer, widgetsPageType: newParams.page_type });
      }
    });
};
