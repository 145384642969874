import { connect } from 'react-redux';
import { State } from 'store/types';
import View from 'components/MetaInfo';

const mapStateToProps = (state: State) => {
  const { homePage } = state;
  return {
    metaTitle: homePage.metaData?.title,
    metaDescription: homePage.metaData?.description,
    metaKeywords: homePage.metaData?.keywords,
    canonical: homePage.metaData?.canonical,
  };
};

export default connect(mapStateToProps)(View);
