import { getImpressionEventData } from './utils';


// impressions: any[] :- any because it can consume different impressions like ( PRODUCT | BANNER )
export const syncImpressionsHandler = (impressions: any[], impressionType: string = '', isIndianRegion: boolean = false) => {
    // Skip events incase region is not india
    if (!isIndianRegion) return;

    if (window && window.na && window.na.sendImpressionEvent && impressions && impressions.length > 0) {
        impressions.map((impression: any) => {
            if (impression.event && impression.event.indexOf('click') === -1) {
                const event: any = getImpressionEventData(impression, impressionType);
                window.na.sendImpressionEvent(event.event_name, event);
            }
            return;
        });
    }
};

