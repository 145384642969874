import BelowTheFold from 'shared/components/BelowTheFoldControl';
import Loading from 'shared/components/Loader';
import React from 'react';
import loadableVisibility from 'react-loadable-visibility/loadable-components';


const Loadable = loadableVisibility(
  () => import(/* webpackChunkName: "home.btf" */'./Loadable'),
  {
    fallback: <Loading className="client" />,
  },
);

const ServerOptimized = (props: any) =>
  (
    <BelowTheFold
      render={() => <Loadable {...props} />}
      loading={() => <Loading />}
    />
  )
;


export default ServerOptimized;
