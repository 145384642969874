import { CustomWidgetRendererProps } from 'components/WidgetRenderer';
import { State } from 'store/types';

export const getApiParams = (state: State, ownProps: CustomWidgetRendererProps) => {
  const { remainingInventoryIds = [], allWidgetsLength = 0, noImpressionImmediatePaginationCall = false, queryParamsObject = {} } = ownProps;
  const paginationParams = {
    isPaginationEnabled: !!state?.remoteConfig?.switches?.isPaginationEnabled,
    bufferSize: state?.remoteConfig?.configs?.dnPaginationConfig?.bufferSize || 3,
    pageLimit: state?.remoteConfig?.configs?.dnPaginationConfig?.pageLimit || 10,
    remainingInventoryIds,
    initialInventoryCount: allWidgetsLength,
    noImpressionImmediatePaginationCall,
  };
  const apiParams = {
    params: queryParamsObject,
    paginationParams,
  };
  return apiParams;
};
