import React, { useEffect, useState } from 'react';
import styled from 'App/styled';
import { useDispatch } from 'react-redux';

import appEvents from 'store/app/actionTypes';
import PageTypes from 'constants/pageTypes';
import MetaInfo from './MetaInfo';
import Loading from 'components/Loader';
import Error from 'shared/components/Error';

import { IMPRESSION_EVENT } from 'constants/homePage';
import BelowTheFold from './belowTheFold';
import { getAppTheme } from 'shared/App/theme';
import { getPageConfigInventory } from './helper';
import { addElementIfNotExists } from 'shared/helpers/utils/Array';
import CustomWidgetRenderer from 'shared/components/WidgetRenderer';
import { noIndexNoFollowContent } from '@nykaa/seo/constants';

const Shimmer = styled(Loading)`
  width: 100%;
  margin-bottom: 10px;
`;

const Wrapper = styled.div`
  padding: 0;
`;

const Title = styled.h1`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: ${({ theme }) => theme.spacing.spacing0};
  padding: ${({ theme }) =>
    `${theme.spacing.spacing0} ${theme.spacing.spacing240} ${theme.spacing.spacing100}`};
  ${({ theme }) => theme.typography.titleSmall};
`;

interface HomeProps {
  isFetchingError: boolean;
  widgets: any[];
  loading: boolean;
  configs: {
    initialInventories: number;
    eagerLoadImages: number;
  };
  isLandingPage?: boolean;
  loadInterFont: boolean;
  isPaginationEnabled?: boolean;
  remainingInventoryIds?: string[];
  queryParamsObject?: object;
  deIndex?: boolean;
  homeh1Tag: string;
}
const HomeView = ({
  widgets,
  loading,
  configs,
  isFetchingError,
  isLandingPage,
  loadInterFont,
  isPaginationEnabled,
  remainingInventoryIds,
  queryParamsObject,
  deIndex,
  homeh1Tag,
}: HomeProps) => {
  const dispatch = useDispatch();
  const [currentBatchLoading, setCurrentBatchLoading] = useState(false);

  const currentBatchLoadingCb = (isLoading: boolean) => {
    setCurrentBatchLoading(isLoading);
  };

  useEffect(() => {
    window.requestIdleCallback(() => {
      dispatch({
        type: appEvents.APP_REDUCER_SET_PAGE,
        payload: PageTypes.HOME,
      });
    });
  }, [dispatch]);

  if (isFetchingError) {
    return <Error />;
  }

  if (loading) {
    return (
      <div>
        {[0, 1, 2].map((item) => (
          <Shimmer
            className="client"
            key={item}
          />
        ))}
      </div>
    );
  }

  if (!widgets || widgets.length < 1) {
    return null;
  }
  const { initialInventories, eagerLoadImages } = configs || {};
  const appConfigWidget = getPageConfigInventory(widgets);
  const widgetsToRender = addElementIfNotExists(
    widgets.slice(0, initialInventories),
    appConfigWidget
  );
  const btfWidgets = addElementIfNotExists(
    widgets.slice(initialInventories),
    appConfigWidget
  );
  const pageData =
    widgets && widgets.length > 0 ? widgets[0].inventoryPageData : 'default';
  const metaRobots = deIndex ? noIndexNoFollowContent : '';

  return (
    <>
      <MetaInfo
        loadInterFont={loadInterFont}
        metaRobots={metaRobots}
      />
      <Wrapper>
        <CustomWidgetRenderer
          widgetList={widgetsToRender}
          isServer={__SERVER__}
          impressionEvent={isLandingPage ? '' : IMPRESSION_EVENT}
          theme={getAppTheme()}
          eagerLoadImages={eagerLoadImages}
          eagerLoadInventories={initialInventories}
          allWidgetsLength={widgets?.length || 0}
          pageData={pageData}
        />
        <BelowTheFold
          widgets={btfWidgets}
          isLandingPage={isLandingPage}
          allWidgetsLength={widgets?.length || 0}
          remainingInventoryIds={remainingInventoryIds}
          queryParamsObject={queryParamsObject}
          pageData={pageData}
          currentBatchLoadingCb={currentBatchLoadingCb}
          noImpressionImmediatePaginationCall={
            isPaginationEnabled && btfWidgets?.length === 0
          }
        />
      </Wrapper>
      {isPaginationEnabled && currentBatchLoading && (
        <Shimmer className="client" />
      )}
      {homeh1Tag && <Title>{homeh1Tag}</Title>}
    </>
  );
};

export default HomeView;
