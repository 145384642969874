import { AB_PAGE_NOT_FOUND } from 'constants/product';
import { getEligibleForVariant } from 'helpers/experimentV2';
import { connect } from 'react-redux';
import { State } from 'store/types';

const mapStateToProps = (state: State) => {
  const { appReducer, remoteConfig } = state;
  return {
    statusCode: appReducer.statusCode,
    showPageNotFoundV2: getEligibleForVariant(state, AB_PAGE_NOT_FOUND, 'A'),
    pageNotFoundTitle: remoteConfig?.configs?.noSearchPage?.pageNotFoundTitle,
    pageNotFoundSubtitle:
      remoteConfig?.configs?.noSearchPage?.pageNotFoundSubtitle,
    redirectText: remoteConfig?.configs?.noSearchPage?.redirectText,
  };
};

/* eslint-disable @typescript-eslint/no-var-requires */
const getView = () => require(`./${__PLATFORM__}/View`).default;

export default connect(mapStateToProps)(getView());
