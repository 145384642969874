import { deviceTypes } from 'shared/constants';
import { logger } from 'logging';
import { ApiHelper } from 'api';
import handleImpressionsUtil from '@nykaa/utils/impressions';
import { Impression } from './types';
import { getClickEventData } from './utils';

export const asyncImpressionsHandler = async (impressions: Impression[], customerId: string = '', isIndianRegion: boolean = false) => {
  // Skip events incase region is not india
  if (!isIndianRegion) return;

  if (impressions && impressions.length > 0) {
    const bannerEvents: Record<string, any>[] = [];

    impressions.map((impression: Impression) => {
      if (impression.event && impression.event.indexOf('click') > -1) {
        bannerEvents.push(getClickEventData(impression));
      }
    });

    if (window && window.na && window.na.sendMultipleEvents && bannerEvents.length > 0) {
      window.na.sendMultipleEvents(bannerEvents);
    }

    handleImpressionsUtil(impressions, customerId, deviceTypes.DESKTOP, logger, ApiHelper);
  }
};
